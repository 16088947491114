import { Card, Center, Heading, Text } from '@company/ui/components';

export default function AuthLayout({
  children,
  title,
  description
}: {
  children: React.ReactNode;
  title: string;
  description: string;
}) {
  return (
    <Center h={'full'} w={'full'}>
      <Card.Root minW={'380px'} borderWidth={1} borderColor={'gray.200'}>
        <Card.Header pb={0}>
          <Heading size={'md'}>{title}</Heading>
          <Text fontSize="sm" color={'GrayText'}>
            {description}
          </Text>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card.Root>
    </Center>
  );
}
