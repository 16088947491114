'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { DefaultValues, FieldValues, useForm, UseFormProps } from 'react-hook-form';
import { z } from 'zod';

export type { FieldValues, Path, UseFormReturn } from 'react-hook-form';

export function useZodForm<TSchema extends z.ZodType>(
  props: Omit<UseFormProps<TSchema['_input']>, 'resolver'> & {
    schema: TSchema;
  }
) {
  const form = useForm<TSchema['_input']>({
    ...props,
    resolver: zodResolver(props.schema)
  });

  return form;
}

export const useResetForm = <TFieldValues extends FieldValues>({
  form,
  isOpen,
  values
}: {
  form: ReturnType<typeof useForm<TFieldValues>>;
  isOpen: boolean;
  values: DefaultValues<TFieldValues>;
}) => {
  useEffect(() => {
    if (isOpen) {
      form.reset(values);
    }
  }, [isOpen, form.reset]);
};

export { useFieldArray } from 'react-hook-form';
